import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
// import HighlightedCode from 'docs/src/modules/components/HighlightedCode';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from "@mui/material/Checkbox";
// import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import { indexOf } from 'lodash';
import { MenuProps, useStyles, options } from "../utils/utils";
import Iconify from '../components/iconify';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuPropss = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  '1960',
  '1970',
  '1980',
  '1990',
  '2000',
  '2010',
  'All Time',

];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}



const style = {
  marginTop: '40px',
  position: "absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
    bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflowY: "auto",
  p: 4,
  height: "518px",
  overflowX: "auto"
};

export default function AddQuiz({taurlyearvalue,taurlcategoryvalue }) {
  const theme = useTheme();

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      const data = selected.length === options.length ? [] : options

      setSelected(data);
      console.log(selected)
      return;
    }
    
    // const data = selected.length === options.length ? [] : options




    setSelected(value);
     
    
    console.log(selected)
  };
  
  
  
  const [selectedvalue,setselectedvalue] = React.useState('') 
  const [selectyear,setselectyear] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [questionDetail, setQuestionDetail] = React.useState({
    question: '',
    correctAnswer: '',
    optionOne: '',
    optionTwo: '',
    optionThree: '',
    optionFour: '',
    optionFive: '',
  });
  // const location = useLocation();
 
 
  const [personName, setPersonName] = React.useState([]);

  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    console.log(personName)
  };

 const handleChangeste = (e)=>{
  // console.log("Name")
setselectedvalue(e.target.value)
 }


 const handleChangeYear = (e)=>{
 
  setselectyear(e.target.value)
   }
 
 
  const onChangeHandle = (evt) => {
    setQuestionDetail({ ...questionDetail, [evt.target.name]: evt.target.value });
  };
  const addNewQuestion = async () => {
    // console.log(`${targetUrl}/admin/new-question${url}`);
    try {
      const token = await JSON.parse(localStorage.getItem('token'));
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/add-question`,

        {
          decadeId : personName,
          levelId: selectedvalue,
          question: questionDetail.question,
          correctAnswer: questionDetail.correctAnswer,
          optionOne: questionDetail.optionOne,
          optionTwo: questionDetail.optionTwo,
          optionThree: questionDetail.optionThree,
          optionFour: questionDetail.optionFour,
          optionFive: questionDetail.optionFive,
          condition1: questionDetail.ConditionOne,
          condition2: questionDetail.ConditionTwo,


        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }


      );

      if(response.status===200)
      {
        setOpen(false)
       handleClose()   
      }
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   console.log('targetUrl', targetUrl);
  // }, [targetUrl]);

  return (
    <div>
      <Button

      style={{float:'right'}}
        onClick={handleOpen}
        size="large"
        color="primary"
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
      >
        new question
      </Button>
      
      <Modal
        style={{overflow : 'scroll'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography textAlign={'center'}  mb={3} id="modal-modal-title" variant="h6" component="h2">
            New Question
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <Box
            component="form"
            sx={{
              '& > :not(style)': {  m: 1, width: '25ch'  },
            }}
            noValidate
            autoComplete="off"
            style={{ marginTop:'40px', marginBottom: '0.4rem', display: 'flex', flexDirection: 'column' }}
          >
            <FormControl 
            
            
            fullWidth>
  <InputLabel
  
  id="demo-simple-select">Category</InputLabel>
  <Select 
  
    labelId="demo-simple-select"
    id="demo-simple"
    
    label="Category"
    onChange={handleChangeste}
  >
    {/* ['genius', 'all-star', 'veteran', 'sophomore', 'rookie'] */}
    <MenuItem value={1}>rookie</MenuItem>
    <MenuItem value={2}>sophomore</MenuItem>

    <MenuItem value={3}>veteran</MenuItem>
    <MenuItem value={4}>all-star</MenuItem>
    <MenuItem value={5}>genius</MenuItem>
  </Select>
</FormControl>


    <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">Year</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={personName}
          onChange={handleChanged}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {names.map((name,index) => (
            <MenuItem
              key={name}
              value={index+1}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.name}
              label="question"
              variant="outlined"
              name="question"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.company}
              label="answer"
              variant="outlined"
              name="correctAnswer"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionOne"
              variant="outlined"
              name="optionOne"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionTwo"
              variant="outlined"
              name="optionTwo"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionThree"
              variant="outlined"
              name="optionThree"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionFour"
              variant="outlined"
              name="optionFour"
              onChange={onChangeHandle}
            />
                        <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionFive"
              variant="outlined"
              name="optionFive"
              onChange={onChangeHandle}
            />
                      <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="Condition One"
              variant="outlined"
              name="ConditionOne"
              onChange={onChangeHandle}
            />
                      <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="Condition Two"
              variant="outlined"
              name="ConditionTwo"
              onChange={onChangeHandle}
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            

            <Button
              style={{ marginRight: '0.4rem' }}
              size="large"
              variant="contained"
              color="warning"
              onClick={handleClose}
            >
              Cancel{' '}
            </Button>
            <Button onClick={() => addNewQuestion()} variant="contained" size="large" color="success">
              Save{' '}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
