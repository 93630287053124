import {useState} from'react';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { json, useNavigate } from 'react-router-dom';
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const theme = createTheme();

export default function SignUp() {
    const navigate = useNavigate();
    const [firstname,setfirstname]=useState("")
    const [lastname,setlastname]=useState("")
    const [password,setpassword]=useState("")
    const [email,setemail]=useState("")
    const [isValidPass, setIsValid] = useState(false);
    const [isValidemail, setIsValidemail] = useState(false);
    const [show,  setShow] = useState("")
    const [status, setStatus] = useState(false)
    const emailregex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[$@#]).{11,}$/; 
    
    const handleforlogin = ()=>{
        navigate('/login')
     }

    const HandleSubmit = async (event) => {
       event.preventDefault();
    const data = new FormData(event.currentTarget);
      console.log({
      email: data.get('email'),
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      password: data.get('password'),
      });


      try {
          const Url = `${process.env.REACT_APP_BACKEND_URL}/api/admin/sign-up`;
          setfirstname(data.get('firstName'))
          setlastname(data.get('lastName'))
          setpassword(data.get('password'))
          setemail(data.get('email'))
          const isValidPassword = passwordRegex.test(password);
          setIsValid(isValidPassword);
        //   const name = firstname+lastname;
          const isValidEmail = emailregex.test(email);
          setIsValidemail(isValidEmail);
          console.log(isValidPass)
          if (isValidPass && isValidemail && firstname) {
              if (isValidPass) {
                  const res = await axios.post(
                      Url,
                      { "fullName": `${firstname} ${lastname}` , "email": email, "password": password },
                      { headers: { 'Content-Type': 'application/json' } }
                  );
                  if (res.status === 200) {
                      setStatus(true)
                      setShow("Successfully Registered !Now Click on Sign in.. ")
                      navigate('/login')
                  } else {
                      setShow("Doest Not Registered successfully ")

                  }

              } else {
                  setShow("Enter Your Password...")
              }
          }
          else {
              setShow("Please Check your email and password")

          }

      } catch (e) {
          console.log(e);
      }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={HandleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid item xs={12}>
               
          {(isValidPass&&isValidemail) ? show : isValidPass ? show: status ? show : show}
        
              </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#" variant="body2" onClick={handleforlogin}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}