import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import { Container } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Category } from '@mui/icons-material';
import UserPage from './UserPage';
import Page404 from './Page404';
import AddQuiz from './AddQuiz';


const themeFunc = ({ theme }) => {
  return {
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  };
};
const Item = styled(Paper)(themeFunc);
const QuizPage = () => {
  const [age, setAge] = React.useState('');
  const [year, setYear] = useState(null);
  const [category, setCatagory] = useState('');
  const [urlyear, setUrlyear] = useState('');
  const [urlcategory, setUrlcategory] = useState('');


  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // useEffect(() => {
  //   url = `/${catagory}/${year}`;
  //   setYear(year);
  //   setCategory(Category);
  // }, [year, catagory]);

  //   useEffect(() => {
  //     console.log(url);
  //   }, [url]);

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {' '}
          Quiz Section
        </Typography>
         <AddQuiz/> 

        <Grid container spacing={3}>
          <Grid item sx={6} md={6} lg={6} flexGrow={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Years</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Years"
                onChange={(event) => 
                 {
                  setYear(event.target.value)
                  console.log(year)
                  // console.log(event.target.value)
                 }
                }
              >
                {[1960, 1970, 1980, 1990, 2000, 2010, 'All Time'].map((y, index) => {
                  return <MenuItem value={index + 1}>{y}</MenuItem>;
                })} 
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>    
                <MenuItem value={30}>Thirty</MenuItem> */}  
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={6} md={6} lg={6} flexGrow={1}>
          {/* {[1960, 1970, 1980, 1990, 2000, 2010, 'All Time'] */}
            <FormControl 
            
            
            fullWidth>
  <InputLabel
  
  id="demo-simple-select-label">Category</InputLabel>
  <Select 
  
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={category}
    label="Category"
    onChange={(evt) => {
      setCatagory(evt.target.value)
        console.log(category);
    }
  }
    // onChange={handleChange}
  >
    {/* ['genius', 'all-star', 'veteran', 'sophomore', 'rookie'] */}
    <MenuItem value={1}>rookie</MenuItem>
    <MenuItem value={2}>sophomore</MenuItem>

    <MenuItem value={3}>veteran</MenuItem>
    <MenuItem value={4}>all-star</MenuItem>
    <MenuItem value={5}>genius</MenuItem>
  </Select>
</FormControl>
          </Grid>
        </Grid>
        <Box mt={4} flexGrow={1}>
          <UserPage urlyearvalue={year} urlcategoryvalue={category} />
        </Box>
     
      </Container>
    </>
  );
};

export default QuizPage;
