/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import axios from 'axios'
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { json, useNavigate } from 'react-router-dom';
import { QueryStats } from '@mui/icons-material';

function ForgotPassword() {
    const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [status, setquerystatus] = useState(false);
  const [show, setshow] = useState("");



   

  async function  handleSubmit(e) {
    e.preventDefault();
    // handle resetting password with email
    // console.log(`Password reset email sent to ${email}`);

    
    console.log("Code")
    try{
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/forgot-password`,
        { email },
        { headers: { 'Content-Type': 'application/json' } }
      );
       console.log(res)
      if(res.status===200)
      {
        console.log("Received")
        setEmail("")

        setquerystatus(true)
            setshow("Check Your email address we have sent a link to your email")
            navigate("/resetpassword")
      }
      else
      {
              setquerystatus(false)       
      }
    }catch(e){
      
console.log(e)
    }

  }
  const handleloginpageredirect=()=>{
    navigate('/login');

  }

  return (
    
	<div className="container">
		<h1>Forgot Password</h1>
		<h6 className="information-text">Enter your registered email to reset your password.  </h6>
		<div className="form-group">
			<input style={{"width":"250px","borderRadius":"5px","fontSize":"15px"}} type="email" name="user_email" id="user_email" onChange={(e)=>{
                setEmail(e.target.value)
            }} />
            {/* <h1>{email}</h1> */}
            <br/>
			<button style={{marginTop:"10px",borderRadius:"5px"}} onClick={handleSubmit}>Submit</button><br/>
            <h1 style={{fontSize:"30px"}}><Link  variant="subtitle2" underline="hover" onClick={handleloginpageredirect}>
          Sign in
        </Link></h1>
 
        {status===true ?<h1>{show}</h1> : show }
      
      
        </div>

	</div>

  );
}

export default ForgotPassword;
