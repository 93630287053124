// ----------------------------------------------------------------------

const account = {
  displayName: JSON.parse(localStorage.getItem('fullName')),
  
  email: JSON.parse(localStorage.getItem('email')),
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;

// localStorage.setItem('token', JSON.stringify(data.token));
// localStorage.setItem('fullName', JSON.stringify(data.admin.fullName));
// localStorage.setItem('id', JSON.stringify(data.admin.id));
// localStorage.setItem('email', JSON.stringify(data.admin.email));
