import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState, useEffect } from 'react';
import RemoveRedEyeOutlined from '@mui/icons-material/RemoveRedEyeOutlined';
import axios from 'axios';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';

// import './css/UserPage.css';

// @mui
import {
  Modal,
  Select,
  InputLabel,
  FormControl,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  ButtonGroup,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  TextField,
  Box,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// Quiz Edit Modal
import QuizEditModal from './QuizEditModal';
// Quiz Add Modal
import AddQuiz from './AddQuiz';
import baseURL from '../baseurl';
import Page404 from './Page404';
// import { previousDay } from 'date-fns';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  marginTop:'90px',height:'518px',overflow:'scroll',overflowX:'auto',overflowY:'auto'
};
///

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const names = [
  '1960',
  '1970',
  '1980',
  '1990',
  '2000',
  '2010',
  'All Time',

];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage(props) {
  const { urlyearvalue, urlcategoryvalue } = props;
  const theme = useTheme();


  console.log('year value',urlyearvalue);
  const [page, setPage] = useState(false);
  const [openDelete, setopendelete] = useState(false);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [count, setCount] = useState(0);
  const [questions, SetQuestions] = useState([]);
  const [selectQ, setSelectedQ] = useState({});
  const [quizModal, setQuizModal] = useState(false);
  const prodUrl =  `${process.env.REACT_APP_BACKEND_URL}/api/admin/getquestions/${urlyearvalue}/${urlcategoryvalue}`;
  const [deleteid,setdeleteid] = useState("");
  const [selectedyear, setselectedyear] = useState('');
  const [selectedvalue, setselectedvalue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [personName, setPersonName] = React.useState([]);


  
  const [decadeIds,setdecadeId] = useState(0);
  // setdecadeId(urlyearvalue);

  const getToken = () => {
    const token = localStorage.getItem('token');
    console.log(token);
    return JSON.parse(token);
  };
  const addNewQuestion = async () => {

    try {
      const token = getToken();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/add-question`,
        {
          levelId: selectedvalue,
          question: questionDetail.question,
          correctAnswer: questionDetail.correctAnswer,
          optionOne: questionDetail.optionOne,
          optionTwo: questionDetail.optionTwo,
          optionThree: questionDetail.optionThree,
          optionFour: questionDetail.optionFour,
          optionFive: questionDetail.optionFive,
        },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      );
      console.log("response data after adding quesitons ",response)
      if (response) {
      console.log("Hafiz")
        // await fetchQuestions();
        // fetchQuestions();
        // setCount(count+1)
        setOpen(false);
        handleClose();
      } else {
        alert(`Not Added Successfully`);
      }
      // console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteQuestion = async () => {
    try {
      const token = getToken();

      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/editquestion`,
        {
          id: selectQ.id,
          question: selectQ.question,
          correctAnswer: selectQ.correctAnswer,
          optionOne: selectQ.optionOne,
          optionTwo: selectQ.optionTwo,
          optionThree: selectQ.optionThree,
          optionFour: selectQ.optionFour,
          optionFive: selectQ.optionFive,
        },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        setOpen(false);
        handleClose();
      }
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const UpdateQuestion = async () => {
    try {
      const token = getToken();
      // alert('token is ', token)
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/editquestion`,
        {
          id: selectQ.id,
          question: selectQ.question,
          correctAnswer: selectQ.correctAnswer,
          optionOne: selectQ.optionOne,
          optionTwo: selectQ.optionTwo,
          optionThree: selectQ.optionThree,
          optionFour: selectQ.optionFour,
          optionFive: selectQ.optionFive,
          condition1: selectQ.condition1,
          condition2: selectQ.condition2,
          decadeId : personName,
        },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {

        await fetchQuestions();
        setOpen(false);
        handleClose();
      } else {
        alert(`Not Updated Successfully`);
      }
      console.log(response.data);
    } catch (e) {
      console.log(e);
    }
  };

  const [questionDetail, setQuestionDetail] = React.useState({
    question: '',
    correctAnswer: '',
    optionOne: '',
    optionTwo: '',
    optionThree: '',
    optionFour: '',
    optionFive: '',
    condition1: '',
    condition2: '',
  });
  // const location = useLocation();


  const onChangeDecade = (e)=>{
console.log(urlyearvalue)
setdecadeId(e.target.value)
  }

  useEffect(() => {
    setdecadeId(urlyearvalue)
  }, [urlyearvalue]);

  const handleChangeYear = (e) => {};
  const handleChange = (e) => {
    setselectedvalue(e.target.value);
  };
  const [question, setquestion] = useState('');
  const onChangeHandle = (evt) => {
    console.log(selectQ);
    console.log(evt.target.name);
    
    setSelectedQ({ ...selectQ, [evt.target.name]: evt.target.value });
  };

  const openModal = (qno) => {
    const findQuestion = questions.find((question) => question.id === qno);

    console.log('Data');
    console.log(findQuestion);
    if (findQuestion) {
      console.log('Code');
      setSelectedQ(findQuestion);
      setQuizModal(true);
      setOpen(true);
      setquestion(selectQ.question);
      console.log(selectQ.correctAnswer);
    }
  };
  const openModalDelete = (qno) => {
    const findQuestion = questions.find((question) => question.id === qno);

    if (findQuestion) {
      console.log('Code');
      setSelectedQ(findQuestion);
      setopendelete(true);
      setdeleteid(findQuestion.id);
      setquestion(selectQ.question);
    }
  };

  const fetchQuestions = async () => {
    // ................ Token .......//

    console.log("Hafiz umer usman")
    const token = getToken();
    // ..............................//
    const config = {
      method: 'get',
      url: prodUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios(config);
    const data = res.data.questions;
    SetQuestions(data);
    setQuizModal(true);
  };

  useEffect(async() => {
   await fetchQuestions()
  }, [count])
  

  const handleDelete = async () => {
    const token = getToken();
    // ..............................//
    const config = {
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/attemptedquestion/remove/${deleteid}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios(config);
    if (res.data) {
       await fetchQuestions();
      setopendelete(false)
    }
  };

  useEffect(() => {
    fetchQuestions();
    return () => {
      setQuizModal(false);
    };
  }, [urlcategoryvalue]);

  useEffect(() => {
    console.log(questions);
  }, [questions]);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleChanged = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    console.log(personName)
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>
      {/* {!!selectQ && (
        <QuizEditModal question={selectQ} quizModal setModalState={setQuizModal} fetchAllQuestion={fetchQuestions} />
      )} */}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          
        
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}> */}

          {/* </Button> */}
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table style={{ display: 'flex', flexDirection: 'column' }}>
                <TableHead style={{ display: 'flex', width: '100% !important' }}>
                  {questions.length >0 ? <TableRow style={{ display: 'flex', flexGrow: 1 }}>
                    <TableCell style={{ mariginRight: '0.8rem' }}>QuestionsNo#</TableCell>
                    <TableCell style={{ flexGrow: 1 }}>Question</TableCell>
                    <TableCell style={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                      Correct answer
                    </TableCell> 

                    {/* </TableCell> */}
                  </TableRow> : <Page404/>   }
                </TableHead>
                <TableBody>
                  {!!questions.length &&
                    questions?.map((ques) => {
                      const {
                        id,
                        question,
                        questionNo,
                        correctAnswer,
                        decadeId,
                        levelId,
                        optionOne,
                        optionTwo,
                        optionThree,
                        optionFour,
                        optionFive,
                      } = ques;
                      const selectedUser = selected.indexOf(questionNo) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell style={{ marginLeft: '1rem' }} padding="checkbox">
                            <></>
                          </TableCell>

                          <TableCell component="th" style={{ paddingLeft: '2rem' }} scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography align="left" variant="subtitle2" noWrap>
                                {id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Typography variant="subtitle2">{question}</Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Typography variant="subtitle2">{correctAnswer}</Typography>
                          </TableCell>

                          <TableCell>
                            <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                              <Button
                                onClick={() => {
                                  openModal(id);
                                }}
                              >
                                EDIT
                              </Button>

                              {/* <Button onClick={() => handleDelete(id)} variant="contained" color="warning"> */}
                              <Button  onClick={() => {
                                  openModalDelete(id);
                                }} variant="contained" color="warning">
                                DELETE
                              </Button>
                            </ButtonGroup>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography textAlign={'center'} mb={3} id="modal-modal-title" variant="h6" component="h2">
            New Question
          </Typography>

          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch'  },
            }}
            noValidate
            autoComplete="off"
            style={{ marginBottom: '0.4rem', display: 'flex', flexDirection: 'column' }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Category"
                onChange={handleChangeYear}
              >
                {/* ['genius', 'all-star', 'veteran', 'sophomore', 'rookie'] */}
                <MenuItem value={1}>rookie</MenuItem>
                <MenuItem value={2}>sophomore</MenuItem>

                <MenuItem value={3}>veteran</MenuItem>
                <MenuItem value={4}>all-star</MenuItem>
                <MenuItem value={5}>genius</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Category"
                onChange={handleChange}
              >
                {/* ['genius', 'all-star', 'veteran', 'sophomore', 'rookie'] */}
                <MenuItem value={1}>rookie</MenuItem>
                <MenuItem value={2}>sophomore</MenuItem>

                <MenuItem value={3}>veteran</MenuItem>
                <MenuItem value={4}>all-star</MenuItem>
                <MenuItem value={5}>genius</MenuItem>
              </Select>
            </FormControl>

            <TextField
              style={{ width: '100%' }}
              // id="outlined-basic"
              //   value={props.user.company}
              // value={"My name is khan"}

              label="question"
              variant="outlined"
              name="question"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              // id="outlined-basic"
              //   value={props.user.company}
              // value={"My name is khan"}
              label="answer"
              variant="outlined"
              name="correctAnswer"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              // id="outlined-basic"
              //   value={props.user.role}
              label="optionOne"
              value="dddd"
              variant="outlined"
              name="optionOne"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              // id="outlined-basic"
              //   value={props.user.role}
              label="optionTwo"
              variant="outlined"
              name="optionTwo"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionThree"
              variant="outlined"
              name="optionThree"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionFour"
              variant="outlined"
              name="optionFour"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionFive"
              variant="outlined"
              name="optionFive"
              onChange={onChangeHandle}
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{ marginRight: '0.4rem' }}
              size="large"
              variant="contained"
              color="warning"
              onClick={handleClose}
            >
              Cancel{' '}
            </Button>
            <Button onClick={() => addNewQuestion()} variant="contained" size="large" color="success">
              Save{' '}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
              style={{marginTop:'90px',height:'518px',overflow:'scroll',overflowX:'auto',overflowY:'auto'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography textAlign={'center'} mb={3} id="modal-modal-title" variant="h6" component="h2">
            Update Question


          </Typography>

          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            style={{ marginBottom: '0.4rem', display: 'flex', flexDirection: 'column' }}
          >
                        <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Year</InputLabel>
             
          <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={personName}
          onChange={handleChanged}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        > 
          {names.map((name,index) => (
            <MenuItem
              key={name}
              value={index+1}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
                {/* ['genius', 'all-star', 'veteran', 'sophomore', 'rookie'] */}
               
            </FormControl>
 

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectQ.levelId}
                label="Category"
                name="levelId"
                onChange={onChangeHandle}
              >
                {/* ['genius', 'all-star', 'veteran', 'sophomore', 'rookie'] */}
                <MenuItem value={1}>rookie</MenuItem>
                <MenuItem value={2}>sophomore</MenuItem>

                <MenuItem value={3}>veteran</MenuItem>
                <MenuItem value={4}>all-star</MenuItem>
                <MenuItem value={5}>genius</MenuItem>
              </Select>
            </FormControl>
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.name}
              value={selectQ.question}
              label="question"
              variant="outlined"
              name="question"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              value={selectQ.correctAnswer}
              label="answer"
              // placeholder={selectQ.answer}
              variant="outlined"
              name="correctAnswer"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionOne"
              value={selectQ.optionOne}
              variant="outlined"
              name="optionOne"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionTwo"
              variant="outlined"
              value={selectQ.optionTwo}
              name="optionTwo"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionThree"
              value={selectQ.optionThree}
              variant="outlined"
              name="optionThree"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionFour"
              value={selectQ.optionFour}
              variant="outlined"
              name="optionFour"
              onChange={onChangeHandle}
            />
            <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="optionFive"
              variant="outlined"
              value={selectQ.optionFive}
              name="optionFive"
              onChange={onChangeHandle}
              
            />


<TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="Condition One"
              value={selectQ.condition1}
              variant="outlined"
              name="condition1"
              onChange={onChangeHandle}
            />
                      <TextField
              style={{ width: '100%' }}
              id="outlined-basic"
              //   value={props.user.role}
              label="Condition Two"
              value={selectQ.condition2}
              variant="outlined"
              name="condition2"
              onChange={onChangeHandle}
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{ marginRight: '0.4rem' }}
              size="large"
              variant="contained"
              color="warning"
              onClick={handleClose}
            >
              Cancel{' '}
            </Button>
            <Button onClick={() => UpdateQuestion()

            } variant="contained" size="large" color="success">
              Update{' '}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography textAlign={'center'} mb={3} id="modal-modal-title" variant="h6" component="h2">
            Are you Sure ; Yo want to delete this record ?
          </Typography>

          <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            style={{ marginBottom: '0.4rem', display: 'flex', flexDirection: 'column' }}
          >
                        <Button onClick={() => setopendelete(false)} variant="contained" size="large" color="success">
              No{' '}
            </Button>
            <Button onClick={()=>handleDelete()} variant="contained" size="large" color="success">
              Yes{' '}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
