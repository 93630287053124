import { useState } from 'react';

import { json, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState('');

const handleforget = ()=>{
  navigate('/forgetpassword');
}

  const handleClick = async () => {

    console.log("Password",password)
    console.log("email",email)
    console.log(process.env.REACT_APP_BACKEND_URL)
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/admin/login`,
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
       console.log(data);
      if (data.success === true) {
        localStorage.setItem('token', JSON.stringify(data.token));
        localStorage.setItem('fullName', JSON.stringify(data.admin.fullName));
        localStorage.setItem('id', JSON.stringify(data.admin.id));
        localStorage.setItem('email', JSON.stringify(data.admin.email));
        navigate('/dashboard/quiz',  { replace: true, state: { userId: 123 } });
      }
     if(!data.success){
      alert("no user exist")
     }
    } catch (err) { 
      console.log(err);
      navigate('/login', { replace: true });
    }

  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(evt) => {
            setEmail(evt.target.value); }}/>

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(evt) => {
            setPassword(evt.target.value); }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" alignSelf="end"  justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" /> */}
        <Link  variant="subtitle2" underline="hover" onClick={handleforget}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
