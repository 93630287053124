import React, { useState } from "react";
import axios from 'axios';
import { json, useNavigate } from 'react-router-dom';


const ResetPassword = () => {
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const passwordRegex = /^(?=.*[A-Z])(?=.*[$@#]).{11,}$/;
  const [isValidPass, setIsValid] = useState(false);
  const [isValidConfirmPass, setIsValidConfirm] = useState(false);
  const [show,  setShow] = useState("")
  const [status, setStatus] = useState(false)
  const handleOtpChange = (event) => {
    setOtp(event.target.value);

     };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {

    const Url = `${process.env.REACT_APP_BACKEND_URL}/api/admin/reset-password`;

 
      const isValidPassword = passwordRegex.test(newPassword);
      setIsValid(isValidPassword);

      console.log(isValidPass)

      const isValidconfirmPassword = passwordRegex.test(confirmPassword);
      setIsValidConfirm(isValidconfirmPassword);

      if (isValidPass && isValidConfirmPass && otp.length===4) {
        if (newPassword === confirmPassword) {
          const res = await axios.post(
            Url,
            { "verificationToken": otp, "password": newPassword },
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (res.status === 200) {
            setStatus(true)
            setShow("Successfully Changed Password")
            navigate('/login')
          } else {
            setShow("Password does not change successfully ")
           
          }

        } else {
          setShow("Password does not match")
        }
      }
      else {
        setShow("Please Enter Valid Otp and Password")

      }

    } catch (e) {
      console.log(e);
    }

   
   
   
    // handle submit logic here
  };

  return (
    // <div className="container" style={{backgroundColor:"aliceblue",height:"100%"}} > 
     
     
      <div className="Out" 
      style=
      {
        {
          height:"100%",
          backgroundColor:"aliceblue",
          display:"flex",
          // flexDirection:"row",
          justifyContent:"center",
          // alignContent: "center",
          alignItems:"center"
        }
       }>
           <div className="In" style={{ backgroundColor:"",height:"330px",width:"280px",}}>

           <form onSubmit={handleSubmit}>
           <h4>Reset Your Password</h4>

      <div style={{marginTop:"0px"}}>
        <label  style={{marginTop:"10px",fontWeight:500}} htmlFor="otp">Enter OTP:</label><br/>
        <input style={{borderRadius:"5px",height:"33px",backgroundColor:"antiquewhite",marginTop:"5px" ,width:"260px"}}
           type="text"
          id="otp"
          value={otp}
          onChange={handleOtpChange}
        />
      </div>
      <div>
        <label htmlFor="newPassword">Enter New Password:</label><br/>
        <input style={{borderRadius:"5px",height:"33px",backgroundColor:"antiquewhite",marginTop:"5px",width:"260px"}}
          type={showPassword ? "text" : "password"}
          id="newPassword"
          value={newPassword}
          onChange={handleNewPasswordChange}
        /><br/>
      </div>
      <div>
        <label style={{marginTop:"10px"}} htmlFor="confirmPassword">Confirm New Password:</label><br/>
        <input style={{borderRadius:"5px", height:"33px", backgroundColor:"antiquewhite", marginTop:"5px", width:"260px"}}
          type={showPassword ? "text" : "password"}
          id="confirmPassword"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        /><br/>
                <input 
          type="checkbox"
          id="showPassword"
          checked={showPassword}
          onChange={handleShowPasswordChange}
        />
        <label style={{marginLeft:"10px"}} htmlFor="showPassword">Show Password</label>
      </div>
      <button style={{marginLeft:"40px",borderRadius:"5px", marginTop:"10px"}} type="submit">Reset Password</button>
    </form>
          {/* {(isValidPass&&isValidConfirmPass) ? show : show}
          {newPassword===confirmPassword ? show : show}
          {isValidPass&&isValidConfirmPass ? show : show}
          {status ? show : show} */}
          {(isValidPass&&isValidConfirmPass) ? show : newPassword===confirmPassword ? show:isValidPass &&
          isValidConfirmPass?show:status ? show : show}
        
           </div>
     </div>
 
       
  );
}

export default ResetPassword;
